import { Link } from 'gatsby';
import React from 'react';
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Discord, Facebook, GitHub, GoogleBusiness, Instagram, LinkedIn, Map, Medium, Pinterest, Reddit, Telegram, Tiktok, Twitter, WeChat, WhatsApp, Youtube } from '../images/connect';

function ConnectWithUS() {
    return (
        <Layout>
            <ScrollUpButton ContainerClassName="custom-scroll-button" />
            <Seo title="Connect with us" />
            <Container className="py-5">
                <Row className="justify-content-center text-center mb-lg-5 mb-3">
                    <Col lg={12} className="mb-3">
                        <div className="section-title">
                            <div>Connect With Us</div>
                            <h3>Connect With Us</h3>
                        </div>
                        <p>We pride ourselves on our availability. Connect with core team members </p>
                    </Col>
                </Row>
                
                <div className='py-1 mb-4'>
                    <div className='connect-withus position-relative text-center'>
                        <img src={Map} alt="connect with us map" className="connect-withus-map img-fluid d-block" />

                        <Social link="https://www.tiktok.com/@hedgepay_" icon={Tiktok} title="Tiktok" />
                        <Social link="https://g.page/r/CXFDI2XaoGvvEAI/review" icon={GoogleBusiness} title="Google Business" />
                        <Social link="http://www.pinterest.com/hedgepay" icon={Pinterest} title="Pinterest" />
                        <Social link="https://www.instagram.com/hedgepay_/" icon={Instagram} title="Instagram" />
                        <Social link="https://chat.whatsapp.com/FfqkTfxSWyy1s2rdX3qrOR" icon={WhatsApp} title="WhatsApp" />
                        <Social link="https://twitter.com/HedgePay_" icon={Twitter} title="Twitter" />
                        <Social link="https://www.youtube.com/channel/UCvsGflvMXzP4UsbO-8KUvHg" icon={Youtube} title="Youtube" />
                        <Social link="https://t.me/hedgepay" icon={Telegram} title="Telegram" />
                        <Social link="https://hedgepay.org/wechat/" icon={WeChat} title="WeChat" />
                        <Social link="https://medium.com/@hedgepay" icon={Medium} title="Medium" />
                        <Social link="https://www.reddit.com/r/HedgePay/" icon={Reddit} title="Reddit" />
                        <Social link="https://www.facebook.com/hedgepay" icon={Facebook} title="Facebook" />
                        <Social link="https://www.linkedin.com/company/hedgepay/" icon={LinkedIn} title="LinkedIn" />
                        <Social link="https://github.com/hedgepaybsc/" icon={GitHub} title="GitHub" />
                        <Social link="https://discord.gg/cPF6d6GBGT" icon={Discord} title="Discord" />
                        
                    </div>
                </div>
            </Container>
        </Layout>
    );
}

export default ConnectWithUS;

const Social = ({link, icon, title}) =>(
    <Link href={link} target="_blank" className={`connect-social d-flex flex-column align-items-center connect-social-${title}`}>
        <span>{title}</span>
        <img src={icon} alt={title} />
    </Link>
)